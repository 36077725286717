import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const TextLoader = () => {
  return (
    <p>
      <FontAwesomeIcon icon={faSpinner} spin /> Loading...
    </p>
  );
};

export default TextLoader;