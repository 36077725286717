import React, { useState, useEffect } from "react";
import ModalContext from "./ModalContext";

import ModalAlert from "components/modal/ModalAlert";

const initModalAlert = {
  open: false,
  title: "ไม่พบข้อมูลเบอร์โทร",
  subTitle: "ระบบไม่พบข้อมูลเบอร์โทรของท่าน<br>กรุณาลองใหม่อีกครั้ง",
  content: "",
};
const ModalProvider = ({ children }) => {
  const [isShow, setIsShow] = useState(false);
  const [content, setContent] = useState(initModalAlert);

  const handleModal = (newData) => {
    setIsShow(newData.open);
    if (newData.open) {
      setContent(newData);
    } else {
      setTimeout(() => setContent(newData), 200);
    }
  };

  return (
    <ModalContext.Provider value={{ modalAlert: { info: handleModal } }}>
      <ModalAlert
        open={isShow}
        title={content.title}
        subTitle={content.subTitle}
        content={content.content}
        onClose={() => handleModal(initModalAlert)}
      />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
