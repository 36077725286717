import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../features/RegisterSlice";

import PageHeader from "layouts/inc/PageHeader";

import icoSuccess from "../../assets/img/ci/ico-success.png";

import useTranslations from "../../i18n/useTranslations";

const Welcome = () => {
  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Navigate */
  const navigate = useNavigate();

  const handleGotoProfile = (e) => {
    e.preventDefault();
    // console.log("You clicked", e.currentTarget.dataset.page);
    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Navigate */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.lineUid === null || stateProfile.custNo === null) {
      navigate("/");
    }

    setDataRegis({
      fullname: "",
      idCard: "",
      birthDate: "",
      mobileNo: "",
      isConsent: false,
    });
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageRegisterSuccess">
        <PageHeader />
        <main>
          <div className="wrapContent">
            <section id="pWelcomeMain">
              <div className="bText">
                <h1>{t.welcomePage.headTitle}</h1>
                <img src="assets/img/ci/ico-line.svg" alt="" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t.welcomePage.headSubTitle,
                  }}
                ></p>
              </div>
              <img src={icoSuccess} alt="" />
              <div className="bBtn">
                <a
                  className="btn"
                  onClick={handleGotoProfile}
                  data-page="profile"
                >
                  {t.welcomePage.btnProfile}
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Welcome;
