import { configureStore } from "@reduxjs/toolkit";
import RegisterReducer from "../features/RegisterSlice";
import ProfileReducer from "../features/ProfileSlice";
import TransactionReducer from "../features/TransactionSlice";
import CouponReducer from "../features/CouponSlice";
import i18nReducer from "../i18n/i18nSlice";

export default configureStore({
  reducer: {
    i18n: i18nReducer,
    register: RegisterReducer,
    profile: ProfileReducer,
    transaction: TransactionReducer,
    coupon: CouponReducer,
  },
});
