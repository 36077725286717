import { useNavigate } from "react-router-dom";

import icoMember from "../../assets/img/ci/menu/ico-member.svg";
import icoWarranty from "../../assets/img/ci/menu/ico-warranty.svg";
import icoPrivilage from "../../assets/img/ci/menu/ico-privilage.svg";

import useTranslations from "../../i18n/useTranslations";

const MenuMember = (props) => {
  const { t } = useTranslations();

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  return (
    <>
      <section id="pActionMenu">
        <div className="bBtn">
          <a
            href="#"
            onClick={handleGotoPage}
            data-page="profile"
            className={props.pageActive === "profile" ? "active" : ""}
          >
            <i>
              <img
                src={icoMember}
                alt=""
              />
            </i>
            <span>{t.menuMember.memberCard}</span>
          </a>
          <a
            href="#"
            onClick={handleGotoPage}
            data-page="warranty"
            className={props.pageActive === "warranty" ? "active" : ""}
          >
            <i>
              <img
                src={icoWarranty}
                alt=""
              />
            </i>
            <span>{t.menuMember.warranty}</span>
          </a>
          <a
            href="#"
            onClick={handleGotoPage}
            data-page="coupon"
            className={props.pageActive === "coupon" ? "active" : ""}
          >
            <i>
              <img
                src={icoPrivilage}
                alt=""
              />
            </i>
            <span>{t.menuMember.privileges}</span>
          </a>
        </div>
      </section>
    </>
  );
};

export default MenuMember;
