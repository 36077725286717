import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTransaction } from "../../features/TransactionSlice";

import useTranslations from "../../i18n/useTranslations";

const CardWarranty = (props) => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateTransaction = useSelector((state) => state.transaction);

  const setDataTransaction = (newData) => {
    const tmpObj = { id: newData.id, data: newData };
    dispatch(setTransaction(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const { t } = useTranslations(); // แปลภาษา

  /* Handle goto warranty detail page */
  const navigate = useNavigate();

  const handleGotoWarrantyDetail = (e) => {
    e.preventDefault();
    // console.log("handleGotoWarrantyDetail >>", props.dataTransaction);

    setDataTransaction(props.dataTransaction);

    navigate('/warranty/detail');
  };
  /* End Handle goto warranty detail page */

  useEffect(() => {
    // if (typeof stateTransaction === "object") {
    //   // console.log(stateTransaction);
    // }
  }, [stateTransaction]);

  return (
    <article
      data-year={props.year}
      data-month={props.month}
      data-day={props.day}
      data-type={props.type}
    >
      <div className="bHead">
        <h2>{props.tranNo}</h2>
        <p>{props.createdDate}</p>
      </div>
      <div className="bDetails">
        <div className="bRow">
          <p>{t.warrantyPage.cardType} :</p>
          <p>{t.warrantyPage[`cardType${props.type}`]}</p>
        </div>
        <div className="bRow">
          <p>{t.warrantyPage.cardBranch} :</p>
          <p>{props.branch}</p>
        </div>
        <div className="bRow">
          <p>{t.warrantyPage.cardTotalPrice} :</p>
          <p>฿{props.grandTotal}</p>
        </div>
        <div className="bRow">
          <p>{t.warrantyPage.cardPoints}</p>
          <p>{props.point} {t.warrantyPage.cardPoint}</p>
        </div>
      </div>
      <div className="bBtn">
        <a href="#" onClick={handleGotoWarrantyDetail}>
          {t.warrantyPage.cardMoreDetail}
        </a>
      </div>
    </article>
  );
};

export default CardWarranty;
