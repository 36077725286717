import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../features/RegisterSlice";
import { useLiff } from "react-liff";

import PageHeader from "layouts/inc/PageHeader";
import PageTitle from "layouts/inc/PageTitle";

import useTranslations from "../../i18n/useTranslations";

import logoColor from "../../assets/img/logo/logo-color.svg";

const Home = () => {
  let { liff } = useLiff();

  const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  /* Set state Register {currentPage} on goNext */
  const navigate = useNavigate();
  const [page, setPage] = useState("home");

  const PageRoute = {
    "auth-user": "/auth-user",
    register: "/register",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { ...stateRegister, currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goNext = () => {
    setCurrentPage(page);
    navigate(PageRoute[page]);
  };
  /* End Set state Register {currentPage} on goNext */

  // const handleCloseLiff = () => {
  //   liff.closeWindow();
  // };

  useEffect(() => {
    // console.log(stateProfile);

    if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
      navigate("/profile");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ห้างเพชรทองโกลด์เด้น 99 จำกัด</title>
        <meta name="title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          name="description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta name="keyword" content="" />
        <meta property="og:title" content="ห้างเพชรทองโกลด์เด้น 99 จำกัด" />
        <meta
          property="og:description"
          content="ห้างเพชรทองโกลด์เด้น 99 จำกัด จำหน่ายสินค้าทุกหมวดหมู่ของเครื่องประดับ ทั้งในรูปแบบทองรูปพรรณ 96.5% ลวดลายสวยงาม ทองคำเปอร์เซ็นต์สูง  รับซื้อและจำนำตามราคาสมาคม..."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="assets/img/share/share-cover.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GOLDEN99" />
      </Helmet>

      <div id="pageRegister">
        <PageHeader />
        <main>
          <div className="wrapContent">
            <PageTitle />

            <section id="pTitleContentMain">
              <div className="bTitleContent">
                <div className="tTag">{t.homePage.headTitle}</div>
                <div className="bLogo">
                  <img src={logoColor} alt="Logo-G99" />
                </div>
                <h2>{t.homePage.headSubTitle}</h2>
                <div className="tStep">
                  <span>1/3</span>
                </div>
              </div>
            </section>
            <section id="pRegisterMain">
              <div className="bRegister">
                <h3>{t.homePage.bodyTitle}</h3>
                <div className="bBtnType">
                  <div className="control-group imgRadio">
                    <label className="control control--radio verify">
                      <input
                        type="radio"
                        name="user-type"
                        checked={page === "auth-user"}
                        onChange={() => {
                          setPrevPage(page);
                          setPage("auth-user");
                        }}
                      />
                      <div className="control__indicator">
                        <div className="bPic"></div>
                        <span>{t.homePage.btnAuthUser}</span>
                      </div>
                    </label>
                    <label className="control control--radio new">
                      <input
                        type="radio"
                        name="user-type"
                        checked={page === "register"}
                        onChange={() => {
                          setPrevPage(page);
                          setPage("register");
                        }}
                      />
                      <div className="control__indicator">
                        <div className="bPic"></div>
                        <span>{t.homePage.btnRegisterUser}</span>
                      </div>
                    </label>
                  </div>
                </div>
                <p
                  dangerouslySetInnerHTML={{ __html: t.homePage.bodyDetail }}
                />
              </div>
            </section>
            <section id="pActionBtn">
              <div className="bBtn">
                {/* <button className="btn outline" onClick={handleCloseLiff}>{t.btn.close}</button> */}
                <button
                  className="btn"
                  onClick={goNext}
                  disabled={!page || page === "home"}
                >
                  {t.btn.next}
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Home;
