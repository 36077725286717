import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslations from "../../i18n/useTranslations";

function PageTitle(props) {
  const stateProfile = useSelector((state) => state.profile); // ไว้ดึง state

  const { t } = useTranslations();

  /* First event render */
  // useEffect(() => {
  //   console.log(stateProfile.lineUid);
  // }, []);
  /* End First event render */

  return (
    <>
      <section id="pTiltlePage">
        <div className="bTitle">
          <div className="tag">
            <span>Golden99</span>Family
          </div>
          <h1>
            {stateProfile.lineUid === null || stateProfile.custNo === null
              ? t.pageTitle.welcome
              : props.titleText}
          </h1>
        </div>
      </section>
    </>
  );
}
export default PageTitle;
