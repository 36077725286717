import { get, post } from "../Config";

export function RegisterCust({ data }) {
  return post({ url: `/api/sb/v1/customer/create`, data, timeOut: 20000 });
}
export function UpdateCust({ data }) {
  return post({ url: `/api/sb/v1/customer/update`, data, timeOut: 20000 });
}
export function SearchCust({ params }) {
  return get({ url: `/api/sb/v1/customer`, params });
}
export function CustProfile({ params }) {
  return get({ url: `/api/sb/v1/customer/profile`, params });
}
export function Transaction({ params }) {
  return get({ url: `/api/sb/v1/transaction`, params });
}

export function CustCoupons({ params }) {
  return get({ url: `/api/sb/v1/customer/coupons`, params });
}
export function CouponHistory({ params }) {
  return get({ url: `/api/sb/v1/customer/coupon/history`, params });
}
export function CreateCoupon({ data }) {
  return post({ url: `/api/sb/v1/customer/coupon/create`, data, timeOut: 20000 });
}

export function OtpRequest({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_request`, data, timeOut: 20000 });
}
export function OtpVerify({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_verify`, data, timeOut: 20000 });
}