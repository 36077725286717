import React, { useEffect, useState } from "react";

import $ from "jquery";
import jBox from "jbox";

import useTranslations from "../../i18n/useTranslations";

const ModalWarrantyFilter = ({
  open = false,
  onClose = () => {},
  maxYear = "",
  minYear = "",
  submitCallback = () => {},
}) => {
  const { t } = useTranslations(); // แปลภาษา

  /* Set select option */
  let optionsYear = [];

  if (minYear !== "" && maxYear !== "") {
    for (let i = maxYear; i >= minYear; i--) {
      optionsYear.push({
        value: i,
        label: i,
      });
    }
  }
  // console.log(optionsYear);
  /* End Set select option */

  const [modalFilter, setModalFilter] = useState(null);

  const openModal = () => {
    modalFilter.open();
  };

  const closeModal = () => {
    modalFilter.close();
    onClose();
  };

  const listenerCloseModal = () => {
    const btnCloseModalFilter = document.getElementById("btnCloseModalFilter");
    btnCloseModalFilter.addEventListener("click", () => {
      closeModal();
    });
  };

  const [valueType, setValueType] = useState("");
  const [valueYear, setValueYear] = useState("");
  const [valueMonth, setValueMonth] = useState("");
  const [dataFilter, setDataFilter] = useState({});

  const listenerResetFilter = () => {
    const btnResetFilter = document.getElementById("btnResetFilter");
    btnResetFilter.addEventListener("click", () => {
      let _eleType = document.getElementById("selectType");
      let _eleYear = document.getElementById("selectYear");
      let _eleMonth = document.getElementById("selectMonth");

      _eleType.value = "";
      _eleYear.value = "";
      _eleMonth.value = "";

      // clearStateFilter();
    });
  };

  const listenerSubmitFilter = () => {
    const btnSubmitFilter = document.getElementById("btnSubmitFilter");
    btnSubmitFilter.addEventListener("click", () => {
      const _eleType = document.getElementById("selectType");
      const _eleYear = document.getElementById("selectYear");
      const _eleMonth = document.getElementById("selectMonth");

      setValueType(_eleType.value);
      setValueYear(_eleYear.value);
      setValueMonth(_eleMonth.value);

      closeModal();
    });
  };

  const clearStateFilter = () => {
    // console.log("clearStateFilter");
    setValueType("");
    setValueYear("");
    setValueMonth("");
  };

  const handleOnSelectChange = (e) => {
    // console.log(e.target.value);
  };

  /* First event render */
  useEffect(() => {
    const initModalFilter = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalFilter"),
      // onOpen: () => {
      //   console.log('open');
      // },
      // onOpenComplete: () => {
      //   console.log('openComplete');
      // },
      // onClose: () => {
      //   console.log('close');
      // },
      // onCloseComplete: () => {
      //   console.log('closeComplete');
      // }
    });
    setModalFilter(initModalFilter);

    return () => {
      initModalFilter.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalFilter useEffect2!! >>", modalFilter, open);

    if (modalFilter !== null && open) {
      openModal();
      listenerCloseModal();
      // setTimeout(() => listenerCloseModal(), 100)

      listenerResetFilter();
      listenerSubmitFilter();
    }
  }, [modalFilter, open]);

  useEffect(() => {
    setDataFilter({
      type: valueType,
      year: valueYear,
      month: valueMonth,
    });
  }, [valueType, valueYear, valueMonth]);

  useEffect(() => {
    submitCallback(dataFilter);
  }, [dataFilter]);

  return (
    <div className="bModal bCard" id="ModalFilter">
      <div className="bInner">
        <div className="bTitle">
          <span>{t.lang === "en" ? "Filters By" : "กรองข้อมูลโดย"}</span>
        </div>
        <div className="bFilterForm">
          <div className="bForm">
            <div className="bRow">
              <div className="control-group">
                <div className="tTitle">
                  {t.lang === "en" ? "Type" : "รูปแบบ"}
                </div>
                <div className="select">
                  <select
                    name="type"
                    id="selectType"
                    onChange={handleOnSelectChange}
                  >
                    <option value="">
                      {t.lang === "en" ? "All" : "ทั้งหมด"}
                    </option>
                    <option value="SAL">
                      {t.lang === "en" ? "Buy" : "ซื้อสินค้า"}
                    </option>
                    <option value="CGA">
                      {t.lang === "en" ? "Change" : "แลกเปลี่ยนสินค้า"}
                    </option>
                  </select>
                </div>
                {/* <label htmlFor="" className="tError">
                    กรุณาเลือกประเภท
                  </label> */}
              </div>
            </div>
            <div className="bRow">
              <div className="control-group">
                <div className="tTitle">
                  {t.lang === "en" ? "Year" : "ปี (ค.ศ.)"}
                </div>
                <div className="select">
                  <select name="year" id="selectYear">
                    <option value={""}>
                      {t.lang === "en" ? "All" : "ทั้งหมด"}
                    </option>
                    {optionsYear.map(({ value, label }, index) => (
                      <option key={index + 1} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <label htmlFor="" className="tError">
                    กรุณาเลือกปี
                  </label> */}
              </div>
            </div>
            <div className="bRow">
              <div className="control-group">
                <div className="tTitle">
                  {t.lang === "en" ? "Month" : "เดือน"}
                </div>
                <div className="select">
                  <select name="month" id="selectMonth">
                    <option value="">
                      {t.lang === "en" ? "All" : "ทั้งหมด"}
                    </option>
                    {t.lang === "en" ? (
                      <>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </>
                    ) : (
                      <>
                        <option value="01">มกราคม</option>
                        <option value="02">กุมภาพันธ์</option>
                        <option value="03">มีนาคม</option>
                        <option value="04">เมษายน</option>
                        <option value="05">พฤษภาคม</option>
                        <option value="06">มิถุนายน</option>
                        <option value="07">กรกฎาคม</option>
                        <option value="08">สิงหาคม</option>
                        <option value="09">กันยายน</option>
                        <option value="10">ตุลาคม</option>
                        <option value="11">พฤศจิกายน</option>
                        <option value="12">ธันวาคม</option>
                      </>
                    )}
                  </select>
                </div>
                {/* <label htmlFor="" className="tError">
                    กรุณาเลือกเดือน
                  </label> */}
              </div>
            </div>
          </div>
          <div className="bBtn">
            <button className="btn outline" id="btnResetFilter">
              {t.lang === "en" ? "Reset" : "รีเซ็ท"}
            </button>
            <button className="btn red btnClose" id="btnSubmitFilter">
              {t.lang === "en" ? "Confirm" : "ยืนยัน"}
            </button>
          </div>
        </div>
      </div>
      <a className="btnCloseModalIcon" id="btnCloseModalFilter"></a>
    </div>
  );
};

export default ModalWarrantyFilter;
